import React from "react"

const Members = () => (
  <div className="members">
    <div className="container flex">
      <section className="crew">
        <h3>
          Crew
          <br />
          Members
        </h3>
        <p>
          We do not rely on cross-referencing from unchecked sources or checked
          boxes to be able to get the full picture. Unlike other forms, this
          self-identifying form gives each person on the project the power to
          tell their full story. Privately. 
        </p>
      </section>
      <section className="biz">
        <h3>Businesses</h3>
        <p>
          Diversity is a value, not a quota. Our quantitative and qualitative
          reports generate actionable insights to guide hiring practices that’ll
          fulfill your values and establish industry benchmarks.
        </p>
      </section>
    </div>
    <p className="faq-text">
      Learn more about our process by visiting the Invoke Insights FAQ Page!
    </p>
  </div>
)

export default Members
