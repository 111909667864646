import React, { useState } from "react"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core/"
import LearnMore from "../../images/learn-more.png"
import { scrollIntoView } from "scroll-js"
import { Modal } from "../style"
import Logo from "../../images/invoke-logo.png"
const GreenRadio = withStyles({
  root: {
    color: "#6fe6ae",
    opacity: "0.5",
    "&$checked": {
      color: "#6fe6ae",
      opacity: "1",
    },
  },
  checked: {},
})(props => <Radio size="small" color="default" {...props} />)

function TopContainer() {
  const [value, setValue] = useState("We hire crew members")
  const [checked, setChecked] = useState(false)
  const [privacy, setPrivacy] = useState(false)
  const [open, setOpen] = useState(false)

  const handleChange = e => {
    setValue(e.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const jumpToNext = () => {
    var myElement = document.body.getElementsByClassName("diversity")[0]
    scrollIntoView(myElement, document.body, { behavior: "smooth" })
  }

  return (
    <div className="top-wrapper">
      <div className="container">
        <Link className="login" to="/login">
          Login
        </Link>

        <div className="flex">
          <div className="intro">
            <h1>
              <img src={Logo} alt="Invoke Insights" />
            </h1>
            <p>
              <span>By non profit free the work</span>
            </p>

            <p>
              Make a positive impact in the production industry by creating a
              more diverse and inclusive work environment.
            </p>
            <div className="learnmore desktop" onClick={jumpToNext}>
              <img src={LearnMore} alt="Learn More" />
            </div>
          </div>

          <div className="form-wrapper">
            <p>To receive updates and resources, fill out the form below</p>

            <form
              action="https://invokeinsights.us1.list-manage.com/subscribe/post?u=e6ba0fe8338c59431c2a4260f&amp;id=b166801e02"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
            >
              <div className="flex">
                <div className="firstname">
                  <label htmlFor="mce-FNAME">First Name </label>
                  <input type="text" name="FNAME" id="mce-FNAME" required />
                </div>

                <div>
                  <label htmlFor="mce-LNAME">Last Name </label>
                  <input type="text" name="LNAME" id="mce-LNAME" required />
                </div>
              </div>
              <label htmlFor="mce-EMAIL">Email Address</label>
              <input type="email" name="EMAIL" id="mce-EMAIL" required />

              <p className="describe">What describes your situation best?</p>

              <RadioGroup name="MMERGE5" value={value} onChange={handleChange}>
                <FormControlLabel
                  value="We hire crew members"
                  control={<GreenRadio />}
                  label="We hire crew members"
                />
                <FormControlLabel
                  value="I am a crew member"
                  control={<GreenRadio />}
                  label="I am a crew member"
                />
              </RadioGroup>

              <label className="checkbox">
                <span className="checkbox__input">
                  <input
                    type="checkbox"
                    name="checkbox"
                    onChange={() => setChecked(!checked)}
                    required
                  />
                  <span className="checkbox__control">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        stroke="#6FE6AE"
                        strokeWidth="3"
                        d="M1.73 12.91l6.37 6.37L22.79 4.59"
                      />
                    </svg>
                  </span>
                </span>
                <span className="radio__label">
                  By signing up you agree to receive email updates for INVOKE
                </span>
              </label>

              <label className="checkbox">
                <span className="checkbox__input">
                  <input
                    type="checkbox"
                    name="checkbox"
                    onChange={() => setPrivacy(!privacy)}
                    required
                  />
                  <span className="checkbox__control">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        stroke="#6FE6AE"
                        strokeWidth="3"
                        d="M1.73 12.91l6.37 6.37L22.79 4.59"
                      />
                    </svg>
                  </span>
                </span>

                <span className="radio__label">
                  I agree to Invoke’s{" "}
                  <a onClick={() => setOpen(true)}>Privacy</a> and{" "}
                  <a
                    href="https://freethework.com/terms-conditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>
                </span>
              </label>

              <input
                type="hidden"
                name="b_d549bfeff7c78c5b265207fa9_b0265f0705"
                tabIndex="-1"
                value=""
              />
              <input
                type="submit"
                value="Sign Up Today"
                name="subscribe"
                id="mc-embedded-subscribe"
              />
            </form>
          </div>
        </div>
        <div className="learnmore mobile" onClick={jumpToNext}>
          <img src={LearnMore} alt="Learn More" />
        </div>
      </div>

      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Modal>
          <DialogTitle>
            Invoke Insights ®<br />
            Privacy Policy{" "}
            <svg
              className="close"
              xmlns="http://www.w3.org/2000/svg"
              width="10.092"
              height="10.092"
              viewBox="0 0 10.092 10.092"
              onClick={handleClose}
            >
              <path
                id="ic_close_24px"
                d="M15.092,6.016,14.076,5l-4.03,4.03L6.016,5,5,6.016l4.03,4.03L5,14.076l1.016,1.016,4.03-4.03,4.03,4.03,1.016-1.016-4.03-4.03Z"
                transform="translate(-5 -5)"
                fill="#aaa9a9"
              />
            </svg>
          </DialogTitle>
          <DialogContent>
            <p>
              <span>Last Updated: March 8, 2024</span>
            </p>
            <p>
              <span>We recognize that your privacy is very important.</span>{" "}
              This privacy policy covers the policies of Free The Work (f/k/a
              Free The Bid) (“FTW”) on the collection, use, and disclosure of
              your information, including any personally identifiable
              information or other data collected that could directly or
              indirectly identify you (“Personal Data”) when you access the
              Invoke Insights® platform at{" "}
              <a
                href="https://www.invokeinsights.org/"
                target="_blank"
                rel="noreferrer"
              >
                www.invokeinsights.org
              </a>{" "}
              (the “Site”), the surveys or anytime you use our other Invoke
              Insights products or services (the Site, surveys and other
              Invoke Insights products and services, altogether, the
              “Services”). The FTW Privacy Policy does not apply to these
              Services.
            </p>
            <p>
              Each time you use our Services, you consent to the collection, use
              and storage of the collected information as described in this
              Privacy Policy. Please read it carefully and contact us at{" "}
              <a href="mailto:invoke@freethework.com">invoke@freethework.com</a>{" "}
              if you have any questions.
            </p>
            <p>
              <span>1. What information do we collect?</span>
            </p>
            <p>
              <span>Updates Signup Information.</span> If you wish to receive
              email updates from FTW, we will ask you to provide the following
              information:
            </p>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>
                Your role in the production industry (for example, whether you
                hire crew members or you are a crew member. This is not Personal
                Data).
              </li>
            </ul>
            <p>
              <span>
                Crew Member--Your Anonymous Input to Our Narrative Form.
              </span>{" "}
              If you are a crew member, to help us empower your visibility, you
              may have the opportunity to fill in a narrative form to tell your
              story. You may have the opportunity to tell us about the
              following, among others:
            </p>
            <ul>
              <li>Your age range;</li>
              <li>Your gender;</li>
              <li>Your pronouns;</li>
              <li>Your sexual orientation;</li>
              <li>Your birth country;</li>
              <li>Your ancestry;</li>
              <li>Your language skills;</li>
              <li>Your veteran status;</li>
              <li>Your disability status;</li>
              <li>Your role on the set; and</li>
              <li>Your day rate.</li>
            </ul>
            <p>
              Once this information enters our system, they will be scrambled to
              produce summarized reports with aggregate data. The summarized
              reports will NOT identify you. You can always choose not to
              provide this information by selecting “Prefer not to say.”
            </p>

            <p>
              <span>Crew Member—Enrollment Information.</span> If you wish to
              enroll, which is completely optional, we will collect your phone
              number. We will use your phone number for contact administrative
              purposes. For example, we will send you text messages to invite
              you to complete the on-set survey. We will also use your phone
              number to send you an authentication code, which you will need in
              the future to pre-fill a form for other productions with
              information you have submitted previously. Your phone number will
              be stored in an encrypted way in our database. No phone numbers
              will be directly linked to your anonymous input.
            </p>

            <p>
              <span>Input to Our Intake Form.</span> If you are a brand,
              production company, agency or studio interested in participating
              in our Invoke Insights program, to help us track your project, you
              will be invited to fill in an intake form to tell us about your
              project. You will be asked to provide information which includes
              production/project name, production type, production start and end
              dates, company you are associated with, your name and work email
              address (altogether, the “Project Information”).
            </p>
            <p>
              <span>Automatically Collected Online Usage Activity</span>
            </p>
            <p>
              As is true of most websites, we gather certain information
              automatically when you visit our Site. FTW uses technology to
              provide online customer support and data to improve or customize
              your user experience. These technologies help us track and analyze
              user traffic and activity, administer and update our Site, to
              improve our performance. This information helps us understand your
              needs and suggest products and services relevant to you.
            </p>
            <p>
              <i>Log Files</i>
            </p>
            <p>
              We keep track of activity on our Site via log files stored on our
              web servers. We collect information such as IP address, browser
              type and version, and pages you view. We also keep track of how
              you got to our Site and any links you click on to leave our Site.
              Once you leave our Site, we do not track you. We use your website
              activity to assist us in offering you a personalized Site
              experience, assist you with technical support, diagnose problems
              with our server, administer our websites, and to tailor our
              product and offerings to you.
            </p>
            <p>
              <i>Web Beacons</i>
            </p>
            <p>
              We access and set cookies using web beacons, also known as
              single-pixel GIFs which are invisible graphical images. These web
              beacons provide us useful information regarding our Site such as
              which pages users access. When we send you emails, we may include
              a single-pixel GIF to allow us to determine the number of people
              who open our emails. When you click on a link in an email, we
              record this individual response to allow us to customize our
              offerings to you.
            </p>
            <p>
              <i>Cookies</i>
            </p>
            <p>
              To collect this information, a cookie is set on your computer or
              device when you visit our Services. Please see section below “How
              We Use Cookies” for more discussion on this topic.
            </p>
            <p>
              In some countries, including countries in the European Economic
              Area (“EEA”), United Kingdom and Switzerland, the information
              referenced above in this paragraph may be considered personal
              information under applicable data protection laws.  You can set
              your browser to remove or reject cookies; however some Services
              may not work properly without cookies.
            </p>
            <p>
              <span>Mobile Device Data.</span> When you access our Services
              through a mobile device, we also collect certain mobile device
              information automatically, including, but not limited to, the type
              of mobile device you use, your mobile operating system, and the
              type of mobile Internet browsers you use.
            </p>
            <p>
              <span>Program & Activity Updates.</span> We collect your name and
              email address if you sign up to receive emails, invites and
              newsletters from us regarding our programs and activities.
            </p>
            <p>
              <span>Services and Customer Support.</span> We collect information
              such as your name and email address to the extent necessary for us
              to provide the Services that you have requested and/or to provide
              customer support.
            </p>
            <p>
              <span>2. How we use cookies?</span>
            </p>
            <p>
              Cookies are small text files which are transferred to your
              computer or mobile device when you visit a website or app. We use
              them to remember your preferences, improve your user experience,
              and help us understand how people are using our Services, so we
              can make them better. Cookies can be session cookies, which expire
              once you close your web browser. Cookies can also be persistent
              cookies, which stay on your device or a set period of time or
              until you delete them. FTW uses the following types of cookies:
            </p>
            <ul>
              <li>
                <span>Strictly Necessary Cookies:</span> These cookies are
                necessary to allow us to operate our Services as you have
                requested.
              </li>
              <li>
                <span>Performance/Analytics Cookies:</span> We use cookies and
                other similar technologies to analyze how our Services are
                accessed, is used, or is performing. We use this information to
                maintain, operate, and continually improve our Services. We may
                also obtain information from our email newsletters or other
                communications we send to you, including whether you opened or
                forwarded a newsletter or clicked on any of its content. This
                information tells us about our newsletters' effectiveness and
                helps us ensure that we're delivering information that you find
                interesting.
              </li>
              <li>
                <span>Functional Cookies:</span> These cookies help us remember
                your preferences and settings to enhance your user experience.
              </li>
            </ul>
            <p>
              Using our Services without cookies is also possible. In your
              browser, you can deactivate the saving of cookies, limit them to
              particular websites, or set the browser to notify you when a
              cookie is sent. You can also delete cookies from your PC hard
              drive at any time (file: "cookies"). Please note that in this case
              you will have to expect a limited page presentation and limited
              user guidance. Most web browsers allow some control of most
              cookies through the browser settings. To find out more about
              cookies, including how to see what cookies have been set and how
              to manage and delete them, visit{" "}
              <a target="_blank" href="http://www.allaboutcookies.org/">
                http://www.allaboutcookies.org/
              </a>
            </p>
            <p>
              <span>3. How do we use the collected information?</span>
              We use the information we collect for the purposes described in
              this Privacy Policy, as covered in any agreement that incorporates
              this Privacy Policy, or as disclosed to you in connection with our
              Services. For example, we will use your information to:
            </p>
            <ul>
              <li>
                Provide and deliver products or services, including email
                updates, conduct crew member surveys, enable crew members to
                pre-fill their narrative forms, and track production;
              </li>
              <li>
                Produce summarized reports utilizing aggregate data to generate
                actionable insights to enable intentional hiring practices and
                establish industry benchmarks;
              </li>
              <li>
                Operate and improve our operations, systems, products, and
                services;
              </li>
              <li>
                Respond to your comments and questions and provide customer
                service;
              </li>
              <li>
                Provide service and support, such as sending confirmations,
                technical notices, updates, security alerts, and administrative
                messages and providing customer support and troubleshooting;
              </li>
              <li>
                Communicate with you and your referrals about promotions,
                upcoming events, and news about products and services offered by
                FTW and our selected partners;
              </li>
              <li>
                Enforce our terms and conditions or protect our business,
                partners, or users; or
              </li>
              <li>
                Protect against, investigate, and deter fraudulent,
                unauthorized, or illegal activity
              </li>
            </ul>
            <p>
              <span>Electronic Communication.</span> Consistent with the above
              purposes and as permitted by applicable law, we may communicate
              with you via electronic messages, including email or text message
              to provide you with the Services. Message and data rates may apply
              for any text messages sent to you from us and to us from you, and
              we are not liable for the cost of any such messages.{" "}
              <span>
                You are not required to provide us with your phone number. By
                providing us with your phone number to enroll, you consent to
                receiving text message from us. You can opt-out by texting “NO”
                or “STOP” when prompted by the text message.
              </span>
            </p>
            <p>
              <span>Aggregate Data.</span> Our mission is to enable intentional
              hiring practices and establish industry benchmarks by generating
              big picture trends utilizing aggregate data. Aggregate data are
              data combined from several measurements. When data is aggregated,
              groups of observations are replaced with summary statistics based
              on those observations. For example, our summarized reports will
              only show the gender composition of the production team as a
              whole, the reports will never show gender composition broken down
              into individuals.{" "}
              <i>
                As this aggregate data does not identify you personally, it will
                not be treated as Personal Data under this Privacy Policy.
              </i>{" "}
              The summary reports embodying the aggregate data will be shared
              with third parties.
            </p>
            <p>
              <span>Legal Basis For Processing.</span> When we process your
              Personal Data we will only do so where at least one of the
              following applies:
            </p>
            <ul>
              <li>
                <i>Provide our service to you:</i> Most of the time, the reason
                we process your Personal Data is to perform the contract that
                you have with us. If you provide us with your email to sign up
                for our email updates, we will use the email address to email
                you the updates. If you provide us with your name and email
                address to set up crew member surveys, we will use your email
                address to contact you to set up the crew member surveys. If you
                are a crew member and you fill in a narrative form with your
                demographic information, we will scramble such demographic
                information to produce summarized reports with aggregate data to
                generate actional insights to enable intentional hiring
                practices and establish industry benchmarks. If you are a crew
                member and you choose to enroll with your phone number, we will
                use your phone number to send you administrative text messages
                and authentication code to enable you to pre-fill a form for
                other productions with information you have submitted
                previously.
              </li>
              <li>
                <i>Legitimate interests:</i> We may use your Personal Data where
                we have legitimate interests to do so. For instance, we may use
                Personal Data to generate aggregate data to provide us with
                insights as to our progress in terms of achieving our mission.
                We will also process Personal Data for administrative, fraud
                detection and other legal purposes.
              </li>
              <li>
                <i>Consent:</i> From time to time, we may ask for your consent
                to use your Personal Data for certain specific reasons. You may
                withdraw your consent at any time by utilizing the opt-out
                features available in your account settings, or by contacting us
                at the address provided at the end of this Privacy Policy.
              </li>
              <li>
                <i>Legal Compliance:</i> When it is necessary for us to use your
                Personal Data to comply with a legal obligations.
              </li>
            </ul>
            <p>
              <span>
                4. Is Information Collected by or Disclosed to Third Parties by
                using the Services?
              </span>
            </p>
            <p>
              We do not share, sell or rent to third parties your information
              except as described in this Privacy Policy. Examples of instances
              in which we share your information are provided below:
            </p>
            <p>
              <span>a. Third party service providers.</span> We, like many
              businesses, sometimes engage other companies to perform certain
              business-related functions on our behalf so that we can focus on
              our core business. Examples of these services include, but are not
              limited to, website evaluation and data analysis, data
              visualization, AI marketing automation and distribution, site
              search and discovery services and, where applicable, data
              cleansing, and marketing and promotional material distribution. We
              authorize them to use this Personal Data only in connection data
              security and privacy standards.
            </p>
            <p>
              <span>b. Business transfers.</span> We may sell, assign, buy,
              transfer or otherwise acquire or dispose of certain of our
              businesses or corporate assets. In the event of such or similar
              event, Personal Data we collected from you may be part of the
              transferred assets. We may also share Personal Data with our
              auditors, attorneys or other advisors in connection with the
              forgoing corporate transactions. You acknowledge and agree that
              any successor to or acquirer of us will continue to have the right
              to use your Personal Data and other information in accordance with
              the terms of this Privacy Policy.
            </p>
            <p>
              <span>c. Links to Third Party Platforms.</span> We may provide you
              with the option to connect with third party services, including
              websites, applications and services through “plug-ins,” widgets,
              buttons and other third party features on and connect with our
              websites, communications or products. To do so, we may share your
              account registration data with such third party platforms to
              enable them to authenticate your account. Third party platforms
              which we link to, may have information practices that are
              different from ours. This Privacy Policy does not apply to the
              activities of the third parties when they are collecting or using
              data for their own purpose or on behalf of others. We are not
              responsible for the activities of these third parties. We
              encourage you to review their privacy policies to understand how
              they use your information.
            </p>
            <p>
              <span>d. Aggregate Data.</span> Our mission is to enable
              intentional hiring practices and establish industry benchmarks by
              generating big picture trends utilizing aggregate data. Aggregate
              data are data combined from several measurements. When data is
              aggregated, groups of observations are replaced with summary
              statistics based on those observations. For example, our
              summarized reports will only show the gender composition of the
              production team as a whole, the reports will never show gender
              composition broken down into individuals.{" "}
              <i>
                As this aggregate data does not identify you personally, it will
                not be treated as Personal Data under this Privacy Policy.
              </i>{" "}
              The summary reports embodying the aggregate data will be shared
              with third parties.
            </p>
            <p>
              <span>e. Website Analytics.</span> We use third-party web
              analytics services—like Google Analytics and Lucky Orange—in our
              online services. These service providers help us analyze how users
              navigate our online services. Information collected as a part of
              Google Analytics is automatically sent to Google, and Google may
              use cookies to help with the analysis. Google Analytics may
              provide demographic, interest-related, geographical and referral
              information that FTW may use to better understand our customer
              base. Session User ID tracking and stitching may also be used to
              further understand how our users access our websites from
              different devices. To learn more about Google Analytics or
              download an opt-out browser add-on, please {" "}
              <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" style={{ color: "#000" }}>click here</a>.
              We also use Lucky Orange analytics system to help improve usability and
              customer experience, and Lucky Orange may use cookies to help with
              the analysis. Lucky Orange may record mouse clicks, mouse
              movements, and scrolling activity. Lucky Orange may also record
              keystroke information that is voluntarily entered on this Site.
              Lucky Orange does not track this activity on any site that does
              not use the Lucky Orange system. To learn more about Lucky
              Orange’s tracking activities and how to restrict their tracking
              activities, please visit their{" "}
              <a
                target="_blank"
                href="https://classic.luckyorange.com/privacy.php"
              >
                Privacy Policy
              </a>
              .
            </p>
            <p>
              <span>f. Legal requirements.</span> We may disclose your Personal
              Data if required to do so by law (including, without limitation
              responding to a subpoena or request from law enforcement, court or
              government agency or other public authorities) or in the good
              faith belief that such action is necessary (i) to comply with a
              legal obligation, (ii) to protect or defend our rights, interests
              or property or that of other customers or users, (iii) to act in
              urgent circumstances to protect the personal safety of users of
              the Services or the public, or (iv) to protect against legal
              liability or potential fraud, as determined in our sole
              discretion.
            </p>
            <p>
              <span>g. Your consent.</span> If we intend to use any of your
              Personal Data collected in any manner that is not specified
              herein, we will inform you of such anticipated use prior to or at
              the time at which such Personal Data is collected or we will
              obtain your consent subsequent to such collection but prior to
              such use. In short, we will honor the choices you make regarding
              your Personal Data and will inform you about any other intended
              uses of such information.
            </p>
            <p>
              <span>
                5. Linking to or from Third Party Social Media Platforms.
              </span>
            </p>
            <p>
              Users may follow FTW on Facebook, Instagram, Twitter and other
              social media platforms as made available from time to time. Users
              should click on the hyperlinks for each site to review the
              applicable privacy policies for more detail about information
              collected from these sites.
            </p>
            <p>
              <span>
                6. How Does FTW Comply with the Children’s Online Privacy
                Protection Act?
              </span>
            </p>
            <p>
              Our Services are directed toward a general audience and are not
              directed at nor intended for use by children. We do not knowingly
              collect information from children under the age of 13 without
              parental consent. If a parent or guardian becomes aware that his
              or her child has provided us with information without their
              consent, he or she should contact us at{" "}
              <a href="mailto:invoke@freethework.com">invoke@freethework.com</a>
              . We will delete such information from our files within a
              reasonable time.
            </p>
            <p>
              <span>7. GDPR+ Data Protection Rights.</span>
            </p>
            <p>
              If you are located in countries that are within the European
              Economic Area (the “EEA”), Switzerland or UK, GDPR and its Swiss
              and UK GDPR counterparts give you rights with respect to your
              personal data, subject to any exemptions provided by the law,
              including the following rights:
            </p>
            <ul>
              <li>Request access to your Personal Data;</li>
              <li>Request correction or deletion of your Personal Data;</li>
              <li>Object to our use and processing of your Personal Data;</li>
              <li>
                Request that we limit our use and processing of your Personal
                Data; and
              </li>
              <li>Request portability of your Personal Data.</li>
            </ul>
            <p>
              Provided that you are enrolled, you can access, correct, or delete
              your personal data by contacting us at{" "}
              <a href="mailto:invoke@freethework.com">invoke@freethework.com</a>
              . We will consider all such requests and provide our responses as
              soon as we can. Please note, however, that personal information
              may be exempt from such requests in certain circumstances, which
              may include circumstances where we need to keep processing your
              personal information for our legitimate interests or to comply
              with a legal obligations. Users located in EEA, United Kingdom or
              Switzerland also have the right to make a complaint to a
              government supervisory authority.
            </p>
            <p>
              <span>8. Argentina Data Protection Rights.</span>
            </p>
            <p>If you are located in Argentina, Argentina’s Personal Data Protection Law No. 25, 326 gives you rights with respect to your personal data, subject to any exemptions provided by the law, including the following rights:</p>
            <ul>
              <li>Request access to your Personal Data. Requests can be made free of charge and at six-monthly intervals unless there is a legitimate reason for more frequent access. Upon receipt of your request, we will provide the requested information within 10 days.</li>
              <li>Request to rectify, update, and, when applicable, delete or keep confidential your Personal Data. If your Personal Data is transferred to a third party, we will notify that third party of any rectification or deletion of personal data within five (5) days of such amendments being made. </li>
            </ul>
            <p>
              Provided that you are enrolled, you can access, rectify, update, and applicable, delete or keep confidential, your Personal Data by contacting us at <a href="mailto:invoke@freethework.com">invoke@freethework.com</a> .  We will consider all such requests and provide our responses as soon as we can.  Please note, however, that personal information may be exempt from such requests in order to protect Argentina’s national defense, order and public safe, or third parties’ rights or interests. Users located in Argentina also have the right to make a complaint to a government supervisory authority.
            </p>
            <p>
              <span>9. Cross-Border Data Transfers</span>
            </p>
            <p>
              Sharing of information laid out in Section 4 sometimes involves
              cross-border data transfers, for instance to the United States of
              America and other jurisdictions. FTW may also subcontract
              processing to, or share your Personal Data with, third parties
              located in countries other than your home country. Your Personal
              Data, therefore, may be subject to privacy laws that are different
              from those in your country of residence.
            </p>

            <p>
              Our Services are hosted on Amazon Web services (“AWS”). Like FTW,
              helping to protect the confidentiality, integrity, and
              availability of customer data is of the utmost importance to AWS,
              as is maintaining customer trust and confidence. For more details
              of AWS’ privacy and security processes and its efforts to comply
              with international privacy laws and regulations (including{" "}
              <a
                target="_blank"
                href="https://aws.amazon.com/compliance/gdpr-center/"
              >
                GDPR
              </a>
              {" "}and Argentina’s Personal Data Protection Law No. 25, 326), please visit{" "}
              <a
                target="_blank"
                href="https://aws.amazon.com/compliance/data-privacy/"
              >
                https://aws.amazon.com/compliance/data-privacy/
              </a>
              . By using our Services, you consent to your personal information
              being transferred to our servers as set out in this policy.
            </p>
            <p>
              Where our Services allow for users located in the European
              Economic Area (“EEA”), UK, Switzerland or Argentina, and when we transfer
              their Personal Data to countries outside of the EEA, UK, Switzerland or Argentina as processors, we transfer the Personal Data in
              accordance with applicable privacy laws and, in particular, those
              appropriate contractual, technical, and organizational measures as
              described in the{" "}
              <a
                target="_blank"
                href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
              >
                Standard Contractual Clauses
              </a>{" "}
              approved by the EU Commission and the <a
                target="_blank"
                href="https://servicios.infoleg.gob.ar/infolegInternet/anexos/265000-269999/269064/texact.htm"
              >
                Argentinian Model Contractual Clauses
              </a> adopted by the Data protection Agency of Argentina, as amended from time to time.  Both the Standard Contractual Clauses are and the Argentinian Model Contractual Clauses are commitments between companies transferring personal data, binding them to protect the privacy and security of your data.
            </p>
            <p>
              <span>10. U.S. Privacy Laws </span>
            </p>
            <p>
              If you are located in the United States, this Privacy Policy explains how we collect, use and disclose your personal information under California, Colorado, Connecticut, Montana, Oregon, Texas, Utah and Virginia privacy laws, to the extent applicable to us. We call these laws collectively, the “U.S. Privacy Laws.” As of the last date when this Privacy Policy was updated, we are not yet subject to the U.S. Privacy Laws.  Although we are not yet subject to the U.S. Privacy Laws, our Privacy Policy currently describes rights afforded to data subjects under GDPR that are similar to the rights afforded to data subjects under the U.S. Privacy Laws. It is contemplated that in the event that the U.S. Privacy Laws apply to us, we will update our Privacy Policy to describe the rights afforded to data subjects under the U.S. Privacy Laws.{" "}
              <span>
                Regardless, we do not sell or share personal information. We do not disclose Personal Data to third parties for the purpose of engaging in targeted advertising.
              </span> We will closely monitor the development of the U.S. Privacy Laws that may apply to our future activities and will implement reasonable administrative, technical and physical security measures for compliance.
            </p>

            <p>
              <span>11. How long does FTW retain information collected?</span>
            </p>
            <p>
              We follow generally accepted standards to store and protect the
              Personal Data we collect, both during transmission and once
              received and stored, including utilization of encryption where
              appropriate. We retain Personal Data only for as long as necessary
              to provide the Services you have requested and thereafter for a
              variety of legitimate legal or business purposes. These might
              include retention periods (i) mandated by law, contract or similar
              obligations applicable to our business operations; (ii) for
              preserving, resolving, defending or enforcing our
              legal/contractual rights; or (iii) needed to maintain adequate and
              accurate business and financial records. If you have any questions
              about the security or retention of your Personal Data, you can
              contact us at{" "}
              <a href="mailto:invoke@freethework.com">invoke@freethework.com</a>
              .
            </p>

            <p>
              <span>12. What is FTW’s Security Policy?</span>
            </p>
            <p>
              We have implemented reasonable administrative, technical and
              physical security measures to protect your personal information
              against unauthorized access, destruction or alteration. For
              example, we limit access to this information to authorized
              employees and contractors who need to know that information in
              order to operate, develop or improve our Services. However,
              although we endeavor to provide reasonable security for
              information we process and maintain, no security system can ever
              by 100% secure.
            </p>

            <p>
              <span>13. How Does FTW Respond to “Do Not Track” Signals?</span>
            </p>
            <p>
              “Do Not Track” is a feature enabled on some browsers that sends a
              signal to request that a web application disable its tracking or
              cross-site user tracking. At present, FTW does not respond to or
              alter its practices when a Do Not Track signal is received.
            </p>

            <p>
              <span>
                14. How Will I Be Notified of Changes to Your Privacy Policy?
              </span>
            </p>
            <p>
              If FTW makes material changes to its Privacy Policy, it will
              notify you by: (i) changing the Last Updated Date at the top of
              the Privacy Policy, (ii) sending an email to its users, and/or
              (iii) adding a statement to the Site.
            </p>

            <p>
              <span>15. Contact Us</span>
            </p>
            <p>
              If you have any questions regarding privacy while using our
              Services, or have questions about our practices, please contact us
              via email at{" "}
              <a href="mailto:invoke@freethework.com">invoke@freethework.com</a>
              .
            </p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Modal>
      </Dialog>
    </div>
  )
}

export default TopContainer
