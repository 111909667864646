import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TopContainer from "../components/TopSection/container"
import DiversityContainer from "../components/Diversity"
import Members from "../components/Members"
import SignUp from "../components/SignUp"
import BlurBGMobile from "../images/blurred_bg_top_mobile.png"
import { HideBetween } from "react-hide-on-scroll"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="INVOKE by FREE THE WORK: protecting your demographic data." />
      <div className="mobile-bg">
        <TopContainer />
        <img className="mobile top-bg" src={BlurBGMobile} />
        <div id="first">
          <DiversityContainer />
        </div>
      </div>
      <div id="bottom">
        <Members />
      </div>
      <SignUp show={false} />
      <HideBetween inverse startDivID="first" endDivID="bottom">
        <SignUp show={true} />
      </HideBetween>
    </Layout>
  )
}

export default IndexPage
