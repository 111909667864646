import React from "react"
import PositiveImpact from "../images/positive-impact.png"
import SunIcon from "../images/sun.png"

const DiversityContainer = () => (
  <div className="diversity-wrapper">
    
    <div className="container">
      <div className="desktop">
        <section className="flex diversity">
          <h2>Diversity</h2>
          <p>
            We are
            <br />
            stories.
          </p>
        </section>

        <section className="flex accountability">
          <h2>accountability</h2>
        </section>
        <section className="flex platform">
          <p>
            not just
            <br />
            demographics.
          </p>
          <h2>platform</h2>
        </section>
        <section className="flex human">
          <h2>with a human touch</h2>
        </section>

        <div className="bottom flex">
          <p>
            Our accountability platform brings a human approach to gathering
            data about the makeup of your team’s identities and cultural
            backgrounds.
          </p>
          <p>
            In order to truly support diversity and inclusion in the workplace,
            we all need to accept and respect the rights of people to identify
            on their own terms. 
          </p>
        </div>
        <div className="positive-impact">
          <div className="flex">
            <section>
              <p>Create a positive impact with more equitable sets.</p>
              <p>
                <span>
                  By anonymizing individual demographics on each production,
                  users can get actionable insights and goals without
                  compromising the privacy and safety of those we are serving
                  and hiring.
                </span>
              </p>
            </section>
            <section>
              <div className="img-container">
                <img className="sun" src={SunIcon} alt="icon" />
                <img src={PositiveImpact} alt="Positive Impact" />
              </div>
            </section>
          </div>
        </div>
      </div>

      <div className="mobile">
        <section>
          <p>
            We are stories.
            <br />
            Not just demographics.
          </p>
          <h2>Diversity accountability platform with a human touch</h2>
          <div className="bottom">
            <p>
              Our accountability platform brings a human approach to gathering
              data about the makeup of your team’s identities and cultural
              backgrounds.
            </p>
            <p>
              In order to truly support diversity and inclusion in the
              workplace, we all need to accept and respect the rights of people
              to identify on their own terms. 
            </p>
          </div>
        </section>
        <div className="positive-impact">
          <div className="img-container">
            <img className="sun" src={SunIcon} alt="icon" />
            <img src={PositiveImpact} alt="Positive Impact" />
          </div>

          <section>
            <p>Create a positive impact with more equitable sets.</p>
            <p>
              <span>
                By anonymizing individual demographics on each production, users
                can get actionable insights and goals without compromising the
                privacy and safety of those we are serving and hiring.
              </span>
            </p>
          </section>
        </div>
      </div>
    </div>
  </div>
)

export default DiversityContainer
